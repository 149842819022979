import React from "react";
import _ from "lodash";
import { graphql } from "gatsby";

import { Layout } from "../components/index";
import { htmlToReact, withPrefix } from "../utils";
import ScriptInjector from "../services/ScriptInjector";

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: { eq: $url }) {
      id
    }
  }
`;

export default class Page extends React.Component {
  constructor(props) {
    super(props);

    const scriptInjector = new ScriptInjector();
    const scriptForPage = scriptInjector.scriptForPage();

    this.state = {
      scriptForPage,
    };
  }

  componentDidMount() {
    if (!this.state.scriptForPage) return;

    const script = document.createElement("script");
    script.src = this.state.scriptForPage.src;
    document.body.appendChild(script);
    this.setState({ script });
  }

  componentWillUnmount() {
    if (!this.state.scriptForPage) return;

    document.body.removeChild(this.state.script);
  }

  render() {
    return (
      <Layout {...this.props}>
        <div className="outer">
          <div className="inner-medium">
            <article className="post post-full">
              <header className="post-header">
                <h1 className="post-title">
                  {_.get(this.props, "pageContext.frontmatter.title", null)}
                </h1>
                {_.get(
                  this.props,
                  "pageContext.frontmatter.subtitle",
                  null
                ) && (
                  <div className="post-subtitle">
                    {htmlToReact(
                      _.get(
                        this.props,
                        "pageContext.frontmatter.subtitle",
                        null
                      )
                    )}
                  </div>
                )}
              </header>
              {_.get(this.props, "pageContext.frontmatter.image", null) && (
                <div className="post-image">
                  <img
                    src={withPrefix(
                      _.get(this.props, "pageContext.frontmatter.image", null)
                    )}
                    alt={_.get(
                      this.props,
                      "pageContext.frontmatter.image_alt",
                      null
                    )}
                  />
                </div>
              )}
              <div className="post-content">
                {htmlToReact(_.get(this.props, "pageContext.html", null))}
              </div>

              {this.state.scriptForPage && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.scriptForPage.iframe,
                  }}
                />
              )}
            </article>
          </div>
        </div>
      </Layout>
    );
  }
}
